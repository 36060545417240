import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Loader from '../Loader'
import request from 'superagent'
import { fileToBase64 } from '../../utils/file'
// @ts-ignore
import Rodal from 'rodal'
// include styles
import 'rodal/lib/rodal.css'
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import client from 'src/services/feathers'
import DocumentPreview from './DocumentPreview'
import heic2any from 'heic2any'

export interface DocumentI {
  id?: number
  name?: string
  status: number
  statusName?: string
  document_type: {
    comment: string
    id?: string
    mustBeSigned?: boolean
    mustBeUploaded?: boolean
  }
  datas?: {
    youSign?: {
      urlProcecure?: string
    }
  }
  documentTypeId?: string
}

export interface DocumentPropsI {
  subscriptionToken: string
  subscriptionId: string
  data: DocumentI
}

export enum ESecurityMethods {
  SMS = 'otp_sms',
  EMAIL = 'otp_email',
}

const DocumentCompo: React.FC<DocumentPropsI> = ({ subscriptionToken, subscriptionId, data }) => {
  const contractService = client.service('contract')
  const document = data
  const [isPending, setIsPending] = useState(false)
  const [isUploded, setIsUploaded] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [signUrl, setSignUrl] = useState<string>()
  const [isSecurityContractModalVisible, setSecurityContractModalVisible] = useState(false)
  const [securityMethod, setSecurityMethod] = useState<ESecurityMethods>()
  const [contractGenerationLoading, setContractGenerationLoading] = useState(false)
  const [fileBase64, setFileBase64] = useState<string | undefined>(undefined)
  const [divHeight, setDivHeight] = useState(190) // Initial height --> default
  const [fileType, setFileType] = useState<'pdf' | 'image' | undefined>(undefined)

  const toggle = () => setIsVisible(!isVisible)
  const toggleSecurityContractModal = () => setSecurityContractModalVisible(!isSecurityContractModalVisible)

  /**
   * UPLOAD
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf, image/heic, image/heic-sequence',
    maxFiles: 2,
    onDrop: async (files) => {
      if (!files.length) return

      setIsPending(true)
      setHasError(false)
      setIsUploaded(true)

      let file = files[0]

      // Vérification du type Blob
      if (!(file instanceof Blob)) {
        console.error("Le fichier sélectionné n'est pas un Blob valide.")
        setHasError(true)
        setIsPending(false)
        return
      }
      try {
        let base64File

        // Conversion du fichier HEIC en PNG si nécessaire
        if (file.type === 'image/heic' || file.type === 'image/heic-sequence') {
          const pngBlob = await heic2any({ blob: file, toType: 'image/png' })
          const convertedBlob = Array.isArray(pngBlob) ? pngBlob[0] : pngBlob
          const convertedFile = new File([convertedBlob], file.name.replace(/\.[^.]+$/, '.png'), {
            type: 'image/png',
          })
          base64File = await fileToBase64(convertedFile)
          setFileType('image')
        } else if (file.type === 'application/pdf') {
          base64File = await fileToBase64(file)
          setFileType('pdf')
        } else {
          base64File = await fileToBase64(file)
          setFileType('image')
        }

        setFileBase64(base64File as string)

        // Envoi du fichier au serveur
        const response = await request
          .put(`${process.env.REACT_APP_API_URL}/upload/${document.id}`)
          .send({
            file: base64File,
            name: file.name,
          })
          .set('subscription_token', subscriptionToken)
          .set('subscription_id', subscriptionId)

        setDivHeight(document.document_type.comment ? 514 : 468)
      } catch (error) {
        console.error('Erreur lors du traitement du fichier :', error)
        setHasError(true)
      } finally {
        setIsPending(false)
      }
    },
  })

  const goToSign = async (document: DocumentI) => {
    if (document.status === 100 && document.datas) {
      if (document.datas.youSign) {
        window.open(document.datas.youSign.urlProcecure, '_blank')
        setSignUrl(document.datas.youSign.urlProcecure)
        toggle()
      }
    }

    if (document.status === 0 && document.datas) setSecurityContractModalVisible(true)
  }

  const generateContract = async () => {
    if (!securityMethod) return
    setContractGenerationLoading(true)

    try {
      await contractService.create({
        documentId: document.id,
        otpMethod: securityMethod,
      })
    } catch (error: any) {
      console.error(error)
      toast.error('Une erreur est survenue lors de la génération du contrat')
    } finally {
      setSecurityMethod(undefined)
      setContractGenerationLoading(false)
      toggleSecurityContractModal()
    }
  }

  return (
    <>
      {document.document_type.mustBeUploaded && (
        <div
          style={{
            border: '1px solid #d22630',
            textAlign: 'center',
            position: 'relative',
            cursor: 'pointer',
            width: 300,
            height: divHeight, // hauteur dynamique
            padding: 10,
            margin: 10,
          }}
          {...getRootProps({ className: 'dropzone test' })}
        >
          {isPending && <Loader />}
          <div style={{ fontSize: 18, marginBottom: 20 }}>{document.name}</div>
          {document.document_type.comment && <p>{document.document_type.comment}</p>}
          <div style={{ marginBottom: 20 }}>{document.statusName}</div>

          {(hasError || !isUploded) && <input {...getInputProps()} />}

          {isUploded && !hasError && !isPending && (
            <>
              <DocumentPreview fileUrl={fileBase64} fileType={fileType} />
              <p style={{ color: 'rgb(38, 210, 60)' }}>Merci, votre document est déposé avec succès</p>

              {/* Bouton pour remplacer le fichier existant */}
              <button
                onClick={() => {
                  // Réinitialiser l'état pour permettre de choisir un nouveau fichier
                  setIsUploaded(false)
                  setFileBase64(undefined)
                  setFileType(undefined)
                  setDivHeight(190)
                }}
                style={{
                  marginTop: '10px',
                  padding: '8px 12px',
                  backgroundColor: '#d22630',
                  color: '#ffffff',
                  border: 'none',
                  cursor: 'pointer',
                  borderRadius: '4px',
                }}
              >
                Modifier le fichier
              </button>
            </>
          )}

          {isUploded && hasError && !isPending && (
            <p style={{ color: '#d22630' }}>
              Une erreur est survenue, merci de déposer un document au format jpg, png ou pdf de moins de 10Mo
            </p>
          )}
          {isPending && <p>Envoie en cours ...</p>}
          {!isUploded && !isPending && <p>Cliquez pour déposer votre document</p>}
        </div>
      )}

      {document.document_type.mustBeSigned && (
        <>
          <div
            className="sign-frame"
            style={{
              border: '1px solid #d22630',
              textAlign: 'center',
              position: 'relative',
              width: 300,
              height: 190,
              cursor: [100].includes(document.status) ? 'pointer' : 'default',
              padding: 10,
              margin: 10,
            }}
            onClick={() => goToSign(document)}
          >
            <div style={{ fontSize: 18, marginBottom: 20 }}>{document.name}</div>
            <div style={{ marginBottom: 20 }}>{document.statusName}</div>

            {[210, 240].includes(document.status) && (
              <p style={{ color: 'rgb(38, 210, 60)' }}>Le document a été signé, merci. </p>
            )}
            {[100].includes(document.status) && (
              <p>
                {`Cliquez ici pour finaliser votre ${
                  document.documentTypeId === 'CONTRACT' ? 'ouverture de compte' : 'mandat'
                }`}{' '}
              </p>
            )}
            {[0].includes(document.status) && document.documentTypeId === 'CONTRACT' && (
              <p>Clique ici pour démarrer la génération du contrat</p>
            )}
            {[0].includes(document.status) && document.documentTypeId === 'SEPA' && (
              <p>Cliquez ici pour choisir la méthode de validation la signature</p>
            )}
          </div>

          <Rodal
            id="confirm-security-contract-modal"
            visible={isSecurityContractModalVisible}
            onClose={toggleSecurityContractModal}
            customStyles={{
              maxWidth: '50rem',
              display: 'flex',
              flexDirection: 'column',
              padding: '0rem',
            }}
          >
            <div className="securityTitle">
              <h2>Méthode de sécurité</h2>
              <p>{`Choisissez votre méthode de sécurité pour ${
                document.documentTypeId === 'CONTRACT' ? 'signer votre contrat' : 'signer votre mandat'
              }`}</p>
            </div>
            <div className="securityButtonChoices">
              <div
                className={`securityChoice ${
                  securityMethod === ESecurityMethods.SMS ? 'securityChoiceSelected' : 'securityChoiceUnselected'
                }`}
                onClick={() => setSecurityMethod(ESecurityMethods.SMS)}
              >
                <FontAwesomeIcon icon={faMobileAlt} style={{ fontSize: '1.5rem' }} />
                <p className="securityChoiceText">SMS</p>
                {securityMethod === ESecurityMethods.SMS ? (
                  <span className="securitySelectedDot"></span>
                ) : (
                  <span className="securityUnselectedDot"></span>
                )}
              </div>
              <div
                className={`securityChoice ${
                  securityMethod === ESecurityMethods.EMAIL ? 'securityChoiceSelected' : 'securityChoiceUnselected'
                }`}
                onClick={() => setSecurityMethod(ESecurityMethods.EMAIL)}
              >
                <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '1.5rem' }} />
                <p className="securityChoiceText">EMAIL</p>
                {securityMethod === ESecurityMethods.EMAIL ? (
                  <span className="securitySelectedDot"></span>
                ) : (
                  <span className="securityUnselectedDot"></span>
                )}
              </div>
            </div>

            {contractGenerationLoading && <Loader />}
            <button className="securityConfirm" disabled={!securityMethod} onClick={generateContract}>
              Confirmer
            </button>
          </Rodal>

          {/* <Rodal
            id="signature-modal"
            visible={isVisible}
            onClose={toggle}
            customStyles={{
              width: 'auto',
              height: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              margin: '0',
              minWidth: 'auto',
              maxWidth: 'auto',
            }}
          >
            <iframe
              style={{
                width: '100%',
                height: '100%',
                minWidth: 'auto',
                maxWidth: 'auto',
              }}
              src={signUrl}
              title="signature frame"
            ></iframe>
          </Rodal> */}
        </>
      )}
    </>
  )
}

export default DocumentCompo
