import fetchService from './fetch'
const codesPostaux = require('codes-postaux')

export type ICountryProps = {
  indicator: string //'+33'
  flag: string //fr
  initial: string //FRA
}

// Définition du type pour les coordonnées géographiques
interface Coordinates {
  lon: number
  lat: number
}

// Définition du type pour un enregistrement de code postal belge
interface PostalRecord {
  column_1: string // Code postal
  column_2: string // Nom de la ville
  column_3: number // Longitude
  column_4: number // Latitude
  coordonnees: Coordinates
  municipality_name_french: string // Nom de la municipalité
  arrondissement: string
  province: string | null // La province peut être null
}

// Définition du type pour la réponse de l'API
interface ApiResponse {
  total_count: number
  results: PostalRecord[]
}

// Définition du type pour le format de retour simplifié
export type CityInfo = {
  codePostal: string
  ville: string
}

export const COUNTRY_DATA: ICountryProps[] = [
  { indicator: '+33', flag: 'fr', initial: 'FRA' },
  { indicator: '+32', flag: 'be', initial: 'BEL' },
  { indicator: '+31', flag: 'nl', initial: 'NLD' },
  { indicator: '+41', flag: 'ch', initial: 'CHE' },
  { indicator: '+44', flag: 'gb', initial: 'GBR' },
  { indicator: '+34', flag: 'es', initial: 'ESP' },
  { indicator: '+351', flag: 'pt', initial: 'PRT' },
  { indicator: '+590', flag: 'gp', initial: 'GLP' },
  { indicator: '+262', flag: 're', initial: 'REU' },
  { indicator: '+696', flag: 'mq', initial: 'MTQ' },
  { indicator: '+594', flag: 'gf', initial: 'GUF' },
]

//retourner la classe du drapeau du pays
export const getCountryFlag = (code: string) => {
  const item = COUNTRY_DATA.find((el) => el.indicator === code)
  return item ? item.flag : ''
}

//retourner les 3 premières lettres initiales du pays
export const getCountryInitial = (code: string) => {
  const item = COUNTRY_DATA.find((el) => el.indicator === code)
  return item ? item.initial : ''
}

//recupère le nom d'une ville francais en fonction du code postal
export const getFrCityByPostalCode = (postalCode: string) => {
  const frCities = codesPostaux.find(postalCode)
  const uniqueCities = [...new Set(frCities.map((entry: any) => entry.nomCommune))]
  // console.log(`FR cities ${postalCode}:`, uniqueCities)
  return uniqueCities
}

//recupère le nom d'une ville belge en fonction du code postal
let cachedPostalData: any[] = [] // Cache en mémoire
async function fetchBeData(): Promise<void> {
  if (cachedPostalData.length > 0) return // Si les données sont déjà chargées, on ne refait pas l'appel API

  const baseUrl = `https://www.odwb.be/api/explore/v2.1/catalog/datasets/code-postaux-belge/records`
  let allResults: PostalRecord[] = []
  let start = 0
  const limit = 100

  try {
    while (true) {
      const url = `${baseUrl}?limit=${limit}&start=${start}`
      const response: ApiResponse = await fetchService(url, 'GET', {})

      if (!response.results || response.results.length === 0) break

      allResults = [...allResults, ...response.results]
      start += limit
    }

    cachedPostalData = allResults
    // console.log(`✅ Données postales chargées (${allResults.length} enregistrements)`)
  } catch (error) {
    console.error('Erreur lors de la récupération des données:', error)
  }
}

export async function getBeCityByPostalCode(postalCode: string) {
  if (cachedPostalData.length === 0) {
    await fetchBeData()
  }

  const filteredCities = cachedPostalData.filter((entry) => entry.column_1 === postalCode)

  if (filteredCities.length === 0) {
    console.log(`Aucune ville trouvée pour le code postal ${postalCode}.`)
    return null
  }
  const uniqueCities = [...new Set(filteredCities.map((entry) => entry.column_2))]

  // console.log(`BE cities ${postalCode}:`, uniqueCities)
  return uniqueCities
}

// Charger les données au démarrage pour éviter l'attente
fetchBeData()

//TEST
// getFrCityByZipCode('78310')
// getBeCityByPostalCode('1300')
