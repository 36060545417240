import { ISubscribeFields } from '.'

type GetManagerRes = {
  isSeveralManagers: boolean
}

export const getManager = (
  values: ISubscribeFields,
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
): GetManagerRes => {
  const isSeveralManagers = !!values.managers && values.managers.length > 1
  return { isSeveralManagers }
}
